import React from "react";
import { MdSpaceDashboard, MdLabelImportantOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCarSide,
  faDashboard,
  faHistory,
  faList,
  faList12,
  faListDots,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";

export const Sidebar = () => {
  return (
    <>
      <div className="sidenav flex md:flex-col items-center md:items-start justify-between">
        <Link
          to="/"
          className="clickable-blue my-2"
        >
          <span className="text-3xl font-extrabold">LOCUS</span>
        </Link>

        <div className="flex md:flex-col">
          <Link style={{ marginBottom: "0.5rem" }} to="/" className="">
            <FontAwesomeIcon style={{ marginRight: "1rem" }} icon={faDashboard} />
            <span className="hidden sm:block md:inline active">Dashboard</span>
          </Link>

          <Link style={{ marginBottom: "0.5rem" }} to="/logs" className="">
            <FontAwesomeIcon style={{ marginRight: "1rem" }} icon={faHistory} />
            <span className="hidden sm:block md:inline">Logs</span>
          </Link>
          <Link style={{ marginBottom: "0.5rem" }} to="/logout" className="">
            <FontAwesomeIcon style={{ marginRight: "1rem" }} icon={faSignOut} />
            <span className="hidden sm:block md:inline">Logout</span>
          </Link>

        </div>
      </div>
    </>
  );
};
