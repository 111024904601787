import { Sidebar } from "./Sidebar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
export const Layout = (props) => {
  return (
    <Container className="h-screen" fluid>
      <Row className="h-full">
        <Col className="col-md-3">
          <Sidebar />
        </Col>
        <Col className=" bg-gray-300 h-full px-4">
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};
